import React, {useState} from "react";
import Layout from "../components/layout/layout";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Snackbar,
  Typography,
} from "@material-ui/core";
import Copy from '../assets/svgs/icons/copy.svg'
import { copyToTheClipboard } from "../utils/helpers";
import { Alert } from "@material-ui/lab";
import CustomButton from "../components/shared/custom-button";
import Phone from '../assets/images/contact/phone.svg'
import Email from '../assets/images/contact/email.svg'
import {
  ANTI_BOT,
  STATUS,
} from "../constants/contact";
import Section from "../components/shared/section";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: 'flex-start',
  },
  mainSection: {
    flex: "auto",
    zIndex: 100,
  },
  title: {
    width: '100%',
    marginBottom: 80,

    [theme.breakpoints.down(840)]: {
      textAlign: 'center',
    },
  },
  description: {
    marginBottom: 30,

    [theme.breakpoints.down(840)]: {
      textAlign: 'center',
    },
  },
  descriptionWithButtons: {
    marginBottom: 30,
    width: '100%',

    [theme.breakpoints.down(840)]: {
      textAlign: 'center',
    },
  },
  buttonsContainer: {
    display: 'flex',
    margin: '5px 0',
    height: 44,
    alignItems: 'center',

    [theme.breakpoints.down(840)]: {
      justifyContent: 'center',
    },
  },
  copyButton: {
    marginBottom: 0,

    [theme.breakpoints.down(840)]: {
      marginLeft: 44,
    },
  },
  startIcon: {
    margin: '0 -4px',
  },
  copyButtonIcon: {
    height: '100%',
    padding: '8px 13.5px',
  },
  buttonIcon: {
    margin: '4px 0',
  },
}))

const initialSnackbarData = {
  status: '',
  text: '',
  open: false,
}

const RefundPolicy = (props) => {
  const classes = useStyles();

  const [snackbarState, setSnackbarState] = useState(initialSnackbarData);

  const handleClick = (data) => {
    setSnackbarState(data);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState(initialSnackbarData);
  };

  const copy = event => {
    const id = event.currentTarget.dataset.id;
    copyToTheClipboard(ANTI_BOT[id].value);
    handleClick({
      status: STATUS.success,
      text: `Slate Pages ${ANTI_BOT[id].title} copied to your clipboard`,
      open: true,
    } )
  }

  const callNumber = () => {
    window && window.open(`tel:${ANTI_BOT.phone.value.replaceAll('test', '')}`, '_self')
  }
  const sendEmail = () => {
    window && window.open(`mailto:${ANTI_BOT.email.value.replaceAll('test', '')}`, '_self')
  }

  let { open, status, text } = snackbarState;

  return (
    <Layout SeoProps={{ title: `ContactUs` }} HeaderProps={{ path: props.path }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={status}>
          {text}
        </Alert>
      </Snackbar>
      <Section
        container
        direction="column"
        alignItems="center"
        pt={10}
        pb={15}
        sectionClassName={classes.mainSection}
        innerClassName={classes.section}
      >

        <Typography variant="h2" className={classes.title}>
          Refund Policy
        </Typography>
        <Typography className={classes.description}>
          All sales are final. We accept returns or exchanges for products damaged in transit or if the incorrect item was shipped to you.
        </Typography>
        <Typography className={classes.description}>
          Refunds & Exchanges: To be eligible for a refund or exchange, you must first email us within 30 days of the order date. Upon receipt of your email we will respond promptly and accordingly and make sure your replacement is sent out. If a replacement item is not available, we will refund the full purchase price.
        </Typography>
        <Typography className={classes.descriptionWithButtons}>
          Your satisfaction is our top priority. Please let us know how we can help at:
          <div className={classes.buttonsContainer}>
            <CustomButton variant="contained"
                          color="primary"
                          mb={3}
                          onClick={sendEmail}
                          className={classes.copyButton}
                          classes={{
                            startIcon: classes.startIcon,
                          }}
                          startIcon={<img alt="email" src={Email} className={classes.buttonIcon}/>}
            />
            <IconButton aria-label="delete" className={classes.copyButtonIcon} onClick={copy} data-id={'email'}>
              <Copy color={'primary'}/>
            </IconButton>
          </div>
          or call
          <div className={classes.buttonsContainer}>
            <CustomButton variant="contained"
                          color="primary"
                          mb={3}
                          onClick={callNumber}
                          className={classes.copyButton}
                          classes={{
                            startIcon: classes.startIcon,
                          }}
                          startIcon={<img alt="phone" src={Phone} className={classes.buttonIcon}/>}
            />
            <IconButton aria-label="delete" className={classes.copyButtonIcon} onClick={copy} data-id={'phone'}>
              <Copy color={'primary'}/>
            </IconButton>
          </div>
        </Typography>
      </Section>
    </Layout>
  );
};

export default RefundPolicy
